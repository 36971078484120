<template>
  <div v-if="$store.state.userInfo" class="mine-container">
    <my-nav-bar
      title="我的"
    />
    <div class="mine-container__header app-main-container">
      <div class="mine-container__headimg">
        <van-image width="80px" height="80px" round :src="$store.state.userInfo.avatar" />
      </div>
      <div class="mine-container__nickname">
        <span>{{ $store.state.userInfo.name }}</span>
      </div>
    </div>
    <div class="mine-container__server">
      <van-cell-group>
        <!-- <van-cell
          class="server-cell mine-container__qrcode"
          icon-prefix="mhj-icon"
          icon="erweima"
          title="上传收款二维码"
          is-link
          center
          @click="handleUpload"
        >
          <van-uploader ref="uploader" style="display: none;" :after-read="afterRead" />
        </van-cell> -->
        <van-cell
          class="server-cell mine-container__promote_sales_commission"
          icon-prefix="mhj-icon"
          icon="shouyin"
          title="特惠购提成"
          is-link
          center
          @click="toPromoteSalesCommissionView"
        />
        <van-cell
          class="server-cell mine-container__commission"
          icon-prefix="mhj-icon"
          icon="huaban40"
          title="业务提成"
          is-link
          center
          @click="toCommissionView"
        />
        <van-cell
          class="server-cell mine-container__pwd"
          icon-prefix="mhj-icon"
          icon="mima1"
          title="修改密码"
          is-link
          center
          @click="handleModifyPwd"
        />
      </van-cell-group>
    </div>
    <div class="mine-container__footer">
      <van-button class="mine-container__logout-btn" type="danger" round @click="handleLogout">退出登录</van-button>
    </div>

    <!--<van-action-sheet v-model="showActionSheet" :actions="actions" @select="onSelect" />-->
    <my-dialog v-model="showDialog" title="修改密码" @confirm="handleConfirm">
      <div class="dialog-input">
        <van-field
          v-model="password"
          label="新密码"
          label-width="60"
          placeholder="请输入密码"
          type="password"
          clearable
        />
      </div>
    </my-dialog>
  </div>
</template>

<script>
import userApi, { logout } from '@/api/user'
import warehouseApi from '@/api/warehouse'
import token from '@/utils/token'
import MyDialog from '@/components/my-dialog'
import md5 from 'js-md5'
import MyNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'Mine',
  components: { MyDialog, MyNavBar },
  data() {
    return {
      info: null,
      showActionSheet: false,
      actions: [],
      showDialog: false,
      password: ''
    }
  },
  created() {
    // 如果没有用户信息，则重新获取
    if (!this.$store.state.userInfo) {
      this.getUserInfo()
    }
  },
  methods: {
    getUserInfo() {
      this.beginLoad()
      userApi.info().then(res => {
        this.endLoad()
        this.$store.commit('setUserInfo', res.data)
      })
    },
    handleWarehouse() {
      if (this.actions.length > 0) { this.showActionSheet = true } else {
        this.beginLoad()
        warehouseApi.list().then(res => {
          res.data.map(warehouse => {
            this.actions.push({
              name: warehouse.name,
              value: warehouse.id
            })
          })
          this.endLoad()
          this.showActionSheet = true
        })
      }
    },
    handleUpload() {
      this.$refs.uploader.chooseFile()
    },
    afterRead(file) {
      this.beginLoad()
      userApi.payQrcode(file.file).then(res => {
        this.success(res.msg)
      })
    },
    onSelect(action) {
      this.beginLoad()
      userApi.changeWarehouse({ warehouse_id: action.value }).then(res => {
        this.success(res.msg)
        this.showActionSheet = false
        this.endLoad()
      })
    },
    handleLogout() {
      this.beginLoad()
      logout().then((result) => {
        this.success(result.msg)
        token.rm()
        this.$router.replace('/login')
      }).catch((err) => {
        console.log(err)
      })
    },
    handleModifyPwd() {
      this.password = ''
      this.showDialog = true
    },
    handleConfirm() {
      const data = {
        password: md5(this.password)
      }
      this.beginLoad()
      userApi.modifyPwd(data).then(res => {
        this.success(res.msg)
      })
    },
    toPromoteSalesCommissionView() {
      this.$router.push({
        path: '/my-commission'
      })
    },
    toCommissionView() {
      this.$router.push({
        path: '/my-commission/commission'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .mine-container {
    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      padding: 30px 0;
    }
    &__nickname {
      margin-top: 10px;
      font-size: 15px;
    }
    &__server {
      margin: 10px 12px;
      border-radius: 6px;
      overflow: hidden;
      /*box-shadow: 0 0 6px 0 rgba(48, 48, 48, 0.07);*/
    }
    &__qrcode {
      .van-cell {
        &__left-icon {
          color: #24A8F4;
          padding-right: 5px;
        }
      }
    }
    &__promote_sales_commission {
      .van-cell {
        &__left-icon {
          color: #24A8F4;
          padding-right: 5px;
        }
      }
    }
    &__commission {
      .van-cell {
        &__left-icon {
          color: #EE0A24;
          padding-right: 5px;
        }
      }
    }
    &__pwd {
      .van-cell {
        &__left-icon {
          color: #4CC168;
          padding-right: 5px;
        }
      }
    }
    &__warehouse {
      height: 55px;
      align-items: center;
      .mhj-icon-qiehuancangku {
        color: #24A8F4;
      }
      .van-cell__title {
        margin-left: 10px;
      }
    }
    &__footer {
      padding: 15px;
    }
    &__logout-btn {
      width: 100%;
    }
  }
  .server-cell {
    height: 50px;
  }
  .dialog-input {
    padding: 10px 15px;
  }
</style>
